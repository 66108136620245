import { FC, ReactElement } from 'react';

import { useTranslation } from '@pxr/i18n';

import styles from './LinkList.module.scss';

type Links = Array<{ url?: string; label?: string; content?: ReactElement }>;

export interface Props {
    links: Links;
    isEmpasized?: boolean;
    isPrefixed?: boolean;
}

export const LinkList: FC<Props> = ({ links, isEmpasized, isPrefixed }) => {
    const __url = useTranslation('url').t;

    const prefix = !isPrefixed ? __url('page') : '';

    return (
        <ul className={`${styles.LinkList} ${isEmpasized ? styles['emphasized'] : ''}`}>
            {links.map(({ url, label, content }, index: number) => {
                const isLink = !!(url && label);
                const listKey = isLink ? url : `linklist-${index}`;
                const linkHref = isLink ? `${prefix}/${url}` : '';

                return (
                    <li className={styles['list-item']} key={listKey}>
                        {isLink ? <a href={linkHref}>{label}</a> : content}
                    </li>
                );
            })}
        </ul>
    );
};
